.page {
  position: relative;
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  margin: 25px;

  &__container {
    width: 100%;
    display: flex;
    justify-content: space-between;
    gap: 15px;
    padding: 0 40px;
  }

  .left_block {
    width: 100%;
    max-width: 720px;

    .title {
      margin-bottom: 24px;

      color: #323232;
      text-align: left;
      font-size: 40px;
      font-weight: 700;
      line-height: 52px;
      letter-spacing: 1px;

      span {
        color: #FCC13F;
      }
    }

    .desc {
      margin-bottom: 64px;

      color: #5F5F5F;
      text-align: left;
      font-size: 24px;
      font-weight: 400;
      line-height: 28.44px;
    }

    button {
      margin-bottom: 60px;
    }
  }

  .right_block {
    width: 100%;
    max-width: 720px;
    height: auto;

    .video {
      width: 100%;
      height: auto;
      flex-shrink: 0;
      box-sizing: border-box;
    }
  }

  .partners {
    display: flex;
    flex-direction: column;
    align-self: start;
    margin-top: 6px;

    &__container {
      display: flex;
      justify-content: flex-start;
      align-items: center;
      padding: 0 40px;
    }

    &__title {
      margin-bottom: 24px;

      color: #323232;
      text-align: left;
      font-size: 20px;
      font-weight: 400;
      line-height: 20px;
      letter-spacing: 1px;
    }

    &__icons {
      display: flex;
      justify-content: space-between;
      align-items: center;
      gap: 50px;
      height: 100%;
      min-height: 41.25px;

      img {
        width: 100%;
        min-width: 140px;
        max-width: 140px;
        height: 100%;
      }
    }
  }

  .gift {
    &__container {
      margin-top: 48px;
    }
  }
}

@media (max-width: 1400px) {
  .page {
    &__container {
      padding: 0;
    }

    .left_block {
      max-width: 100%;

      .title {
        font-size: 2.8vw;
        line-height: 3.8vw;
      }

      .desc {
        max-width: 500px;
        font-size: 1.8vw;
        line-height: 1.8vw;
      }
    }

    .right_block {
      width: 100%;
      max-width: 100%;
      height: auto;
    }

    .partners {
      &__container {
        padding: 0;
      }
    }

    .gift {
      &__container {
        margin-top: 48px;
      }
    }
  }
}
@media (max-width: 1080px) {
  .page {
    .left_block {
      .desc {
        margin-bottom: 25px;
      }

      button {
        margin-bottom: 25px;
      }
    }
  }
}
@media (max-width: 900px) {
  .page {
    .partners {
      &__title {
        font-size: 2.0vw;
        line-height: 2.0vw;
      }
    }

    .gift {
      &__container {
        flex-wrap: wrap;
        margin-top: 25px;
      }
    }
  }
}

@media (max-width: 767.9px) {
  .page {
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: start;
    background: none;

    &__container {
      display: flex;
      flex-direction: column;
      justify-content: flex-start;
      align-items: start;
      gap: 25px;
      padding: 0;
    }

    .left_block {
      order: 3;
      width: 100%;

      .title {
        margin-bottom: 24px;

        color: #323232;
        text-align: left;
        font-weight: 700;
        letter-spacing: 1px;

        font-size: 5vw;
        line-height: 6vw;

        span {
          color: #FCC13F;
        }
      }

      .desc {
        color: #5F5F5F;
        text-align: left;
        font-weight: 400;

        font-size: 3vw;
        line-height: 3vw;
      }
    }

    .right_block {
      order: 2;
    }

    .partners {
      order: 1;
      width: 100%;
      margin-top: 6px;

      &__title {
        margin-bottom: 24px;

        color: #323232;
        text-align: left;
        font-size: 20px;
        font-weight: 400;
        line-height: 20px;
        letter-spacing: 1px;
      }

      &__icons {
        display: flex;
        justify-content: space-between;
        align-items: center;
        flex-wrap: wrap;
        gap: 10px;

        img {
          width: 100%;
          min-width: 140px;
          max-width: 140px;
          height: 100%;
        }
      }
    }
  }
}
@media (max-width: 500px) {
  .page {
    height: calc(100dvh - 128px);

    &__container {
      height: 100%;
    }

    .left_block {
      height: calc(100dvh - 128px);
      display: flex;
      flex-direction: column;

      .title {
        font-family: var(--font-family-inter);
        font-size: 22px;
        font-weight: 700;
        line-height: 26.63px;
        letter-spacing: 0.5px;
        text-align: left;
      }

      .desc {
        font-family: var(--font-family-inter);
        font-size: 16px;
        font-weight: 400;
        line-height: 19.36px;
        letter-spacing: 1px;
        text-align: left;
      }

      .card {
        margin-bottom: 25px;
      }

      a {
        margin: auto 0 15px;
        justify-self: flex-end;

        button {
          margin-bottom: 0;
        }
      }
    }
  }
}