.modal {
  &__header {
    width: calc(100%);
    height: 60px;
    box-sizing: border-box;
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 10px 0;
    background: #393939;

    .header_logo {
      max-width: 126px;
      height: auto;
      margin: 0 auto;

      img {
        width: 100%;
        height: auto;
      }
    }

    .header_icon {
      width: 12px;
      height: 12px;
      margin-right: 26px;
      cursor: pointer;

      img {
        width: 100%;
        height: 100%;
      }
    }
  }
}

@media (max-width: 655px) {
  .modal {
    &__form {
      margin: 25px 0;
      padding: 15px;
      overflow: auto;
      max-height: 100%;
    }
  }
}