.container {
  display: flex;
  gap: 64px;

  .left_block {
    width: 100%;
    max-width: 544px;
  }

  .right_block {
    width: 100%;
    max-width: 544px;

    img {
      width: 100%;
      height: auto;
    }
  }
}

@media (max-width: 700px) {
  .container {
    //display: flex;
    //justify-content: center;
    gap: 15px;
    //flex-wrap: wrap;

    //.left_block {
    //  order: 1;
    //  width: 100%;
    //  max-width: 100%;
    //}
    //
    //.right_block {
    //  order: 0;
    //  width: 100%;
    //  max-width: 300px;
    //
    //  img {
    //    width: 100%;
    //    height: auto;
    //  }
    //}
  }
}
@media (max-width: 650px) {
  .container {
    display: flex;
    justify-content: center;
    gap: 15px;
    flex-wrap: wrap;

    .left_block {
      order: 1;
      width: 100%;
      max-width: 100%;
    }

    .right_block {
      order: 0;
      width: 100%;
      max-width: 100%;

      img {
        width: 100%;
        height: auto;
      }
    }
  }
}
@media (max-width: 500px) {
  .container {
    gap: 32px;
  }
}