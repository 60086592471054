.card {
  display: flex;
  flex-direction: column;
  gap: 8px;

  &__block {
    display: flex;
    justify-content: flex-start;
    align-items: center;
    gap: 6px;

    p {
      color: #141414;
      font-family: var(--font-family-inter);
      font-size: 16px;
      font-weight: 500;
      line-height: 19.36px;
      letter-spacing: 1px;
      text-align: left;
    }

    img {
      width: 32px;
      min-width: 32px;
      max-width: 32px;
      height: 32px;
      min-height: 32px;
      max-height: 32px;
    }
  }
}