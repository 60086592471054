.page {
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 0;

  .container {
    display: flex;
    justify-content: center;
    align-items: start;
    margin: 25px;

    .breadcrumb {
      margin-bottom: 30px;
      color: #828B9B;
      font-size: 18px;
      font-weight: 400;
      line-height: 20.7px;
      text-align: left;
    }

    .questions {
      width: 100%;
      padding: 24px 24px 80px;
      border-radius: 8px;
      border: 1px solid #EDEDED;
      background: #FFFFFF;
      box-shadow: 3px 4px 13px 1px #00000040;

      &__title {
        margin: 24px 0;

        color: #323232;
        font-size: 33px;
        font-weight: 700;
        line-height: 42.9px;
        letter-spacing: 1px;
        text-align: left;
      }

      &__content {
        margin: 23px 0;
      }
    }
  }
}

@media (max-width: 655px) {
  .page {
    padding: 0;

    .container {
      margin: 0;
    }
  }
}
@media (max-width: 500px) {
  .page {
    .container {
      padding: 15px;

      .breadcrumb {
        margin-bottom: 15px;

        font-size: 16px;
        font-weight: 400;
        line-height: 18.4px;
        text-align: left;
      }

      .questions {
        width: 100%;

        &__title {
          margin-bottom: 15px;

          font-size: 22px;
          font-weight: 400;
          line-height: 25.3px;
          text-align: left;
        }
      }
    }
  }
}