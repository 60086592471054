@import './variables.scss';
@import './page-layout.scss';

* {
  margin: 0;
  padding: 0;
  font-family: var(--font-family);
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

html {
  scroll-behavior: smooth
}

a {
  color: var(--white);
  text-decoration: none;

  &:hover {
    text-decoration: underline;
  }
}

ul, li {
  list-style: none;
}

.text-underline {
  text-decoration: underline;
}

.container {
  &__desktop,
  &__desktop_outside,
  &__desktop_full {
    width: 100%;
    box-sizing: border-box;
  }
  &__desktop {
    max-width: 1200px;
  }
  &__desktop_outside {
    max-width: 1600px;
  }
  &__desktop_full {
    max-width: 1920px;
  }
}
.flex {
  &__col_center,
  &__row_center {
    display: flex;
    justify-content: center;
    align-items: center;
  }
  &__col_center {
    flex-direction: column;
  }
  &__row_center {
    flex-direction: row;
  }
}