.layout {
  height: 100%;
  min-height: 78px;
  max-height: 78px;
  box-sizing: border-box;
  display: flex;
  justify-content: center;
  padding: 10px 30px;
  gap: 10px;
  background: #393939;

  // text
  color: #FFFFFF;

  .container {
    justify-content: space-between;
  }
}