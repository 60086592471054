.page {
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 0;

  .page_container {
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    padding: 25px;
  }

  .container {
    width: 100%;
  }

  .content {
    display: flex;
    justify-content: space-between;
    align-items: center;
    gap: 64px;
    margin-top: 30px;

    .left_block {
      width: 100%;
      max-width: 573px;
      display: flex;
      justify-content: center;
      align-items: flex-start;

      form {
        max-width: 445px;
      }
    }

    .right_block {
      width: 100%;
      max-width: 963px;
      height: 100%;
      display: flex;
      justify-content: center;
      align-items: center;
      //flex-wrap: wrap;
      gap: 24px;
      padding: 32px 20px;
      border-radius: 8px;

      background: #39393980;
      border: 1px solid #FCC13F;
      box-shadow: 3px 4px 13px 1px #00000040;

      .gift_desc {
        max-width: 432px;
        display: flex;
        flex-direction: column;
        gap: 32px;

        &__block1 {
          color: #FFFFFF;
          font-size: 33px;
          font-weight: 700;
          line-height: 42.9px;
          letter-spacing: 1px;
          text-align: center;
        }
        &__block2 {
          color: #FFFFFF;
          font-size: 33px;
          font-weight: 500;
          line-height: 39.11px;
          text-align: center;

          p:last-child {
            margin-top: 8px;

            color: #FCC13F;
            font-family: var(--font-family-monserat);
            font-size: 48px;
            font-weight: 600;
            line-height: 58.51px;
            text-align: center;
          }
        }
        &__block3 {
          color: #FFFFFF;
          font-size: 24px;
          font-weight: 500;
          line-height: 28.44px;
          text-align: center;

          p:last-child {
            margin-top: 16px;

            color: #DBDBDB;
            font-size: 16px;
            font-weight: 400;
            line-height: 18.96px;
            letter-spacing: 1px;
            text-align: center;
          }
        }
      }

      .gift_image {
        max-width: 467px;
        height: auto;

          img {
            width: 100%;
            height: auto;
          }
        }
    }
  }
}

@media (max-width: 1600px) {
  .page {
    .content {
      gap: 32px;
    }
  }
}
@media (max-width: 1400px) {
  .page {
    .content {
      gap: 32px;

      .right_block {
        flex-wrap: wrap;
      }
    }
  }
}

@media (max-width: 767.9px) {
  .page {
    .page_container {
      padding-top: 0;
    }

    .content {
      width: 100%;
      display: flex;
      justify-content: center;
      gap: 32px;
      flex-wrap: wrap;

      .left_block {
        order: 1;
        width: 100%;
        max-width: 100%;
        padding: 0;
        margin: 0;

        form {
          width: 100%;
          max-width: 100%;

          button {
            margin: auto;
          }
        }
      }

      .right_block {
        order: 0;
        width: 100%;
        max-width: 100%;
      }
    }
  }
}
@media (max-width: 500px) {
  .page {
    .page_container {
      width: 100%;
      height: 100%;
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
      padding: 25px;
    }

    .container {
      width: 100%;
    }

    .content {
      display: flex;
      justify-content: space-between;
      align-items: center;
      gap: 64px;
      margin-top: 0;

      .left_block {
        width: 100%;
        max-width: 573px;
        display: flex;
        justify-content: center;
        align-items: flex-start;

        form {
          max-width: 445px;
        }
      }

      .right_block {
        display: none;
      }
    }
  }
}