.chat-container {
  width: 100%;
  display: flex;
  flex-direction: column;
  gap: 16px;
}

.chat-messages {
  display: flex;
  flex-direction: column;
  gap: 16px;
}

.message-container {
  display: flex;
  justify-content: flex-start;
}

.user-message,
.bot-message {
  max-width: calc(100% - 42px);
  display: flex;
  align-items: flex-end;

  img {
    width: 32px;
    height: 32px;
    margin-right: 10px;
  }
}

.message-text {
  padding: 8px 12px;
  border-radius: 6px;

  color: #141414;
  font-family: var(--font-family-inter);
  font-size: 16px;
  font-weight: 400;
  line-height: 22.4px;
  text-align: left;
}

.user-message {
  align-self: flex-end;
  align-items: center;
  margin-left: auto;

  img {
    margin-right: 0;
    margin-left: 10px;
  }

  .message-text {
    background-color: #FFDB8B;
  }
}

.bot-message {
  width: 100%;
  align-self: flex-start;

  .message-text {
    width: 100%;
    background-color: #FFFFFF;

    .bot-name {
      height: 100%;
      width: 100%;
      display: block;
      margin-bottom: 4px;

      color: #5F5F5F;
      font-family: var(--font-family-inter);
      font-size: 13px;
      font-weight: 400;
      line-height: 15.73px;
      text-align: left;
    }
  }

  img {
    transition: transform 3s ease;
  }

  span {
    width: 32px;
    height: 32px;
    margin-right: 15px;
  }
}

.options {
  width: 100%;
  display: flex;
  flex-wrap: wrap;
  justify-content: flex-end;
  opacity: 1;
  transition: opacity 0.6s ease;

  button {
    margin: 5px;
    padding: 10px 20px;
    border: none;
    border-radius: 16px;
    background: #AFAFAF;
    box-shadow: 0 1px 2px 0 #1018280A;
    cursor: pointer;

    // text
    color: #FFFFFF;
    font-family: var(--font-family-inter);
    font-size: 16px;
    font-weight: 400;
    line-height: 19.36px;
    letter-spacing: 1px;
    text-align: left;

    transition: all 0.2s ease;

    &:hover {
      color: #141414;
      background-color: #FFDB8B;
      transition: all 0.2s ease;
    }
  }
}

.options-hidden {
  opacity: 0;
}