.form {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 24px;

  .title {
    color: #FFFFFF;
    font-size: 16px;
    font-weight: 400;
    line-height: 20.8px;
    letter-spacing: 1px;
    text-align: left;

    span {
      color: #FCC13F;
    }
  }

  .input_block {
    width: 100%;
    display: flex;
    flex-direction: column;

    input {
      height: 47px;
      box-sizing: border-box;
      padding: 12px 16px;
      border: 1px solid #D4D4D4;
      border-radius: 6px;

      color: #0A0B07;
      font-size: 16px;
      font-weight: 400;
      line-height: 18.96px;
      letter-spacing: 1px;
      text-align: left;
    }

    input::placeholder {
      color: #0A0B0780;
    }
  }

  .input_block_error {
    input {
      border: 1px solid #E33E3E !important;
    }
  }

  .logo {
    width: 100%;
    max-width: 314.3px;
    height: 100%;
  }

  .errors {
    padding: 8px 16px;
    margin: 8px 0 0 0;
    border-radius: 6px;
    background: #E33E3E;

    color: #EFEFEF;
    font-size: 12px;
    font-weight: 400;
    line-height: 15.6px;
    text-align: left;
    white-space: pre-line;
  }
}

@media (max-width: 655px) {
  .form {
    .logo {
      display: none;
    }
  }
}
@media (max-width: 400px) {
  .form {
    .input_block {
      input {
        background: #F9F8F8;
      }
    }

    .errors {
      padding: 8px 1px 0 1px;
      margin: 0;
      background: transparent;

      color: #CB0808;
      font-size: 12px;
      font-weight: 400;
      line-height: 15.6px;
      text-align: left;
    }

    button {
      color: #FFFFFF;
    }
  }
}