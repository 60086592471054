.gift {
  //width: 100%;
  height: 132px;
  box-sizing: border-box;
  display: flex;
  justify-content: space-between;
  align-items: center;
  gap: 20px;
  padding: 16px 32px;
  border-radius: 8px;
  cursor: pointer;

  background: #393939 url("../../../../shared/assets/images/gift/GoldGreyImg.webp");
  background-position: center -35px;
  background-size: 319px;
  background-repeat: no-repeat;

  .left_block {
    box-sizing: border-box;
    width: 100%;
    max-width: 389px;

    .gift_sum {
      width: 100%;
      height: 48px;
      box-sizing: border-box;
      display: flex;
      justify-content: center;
      align-items: center;
      padding: 8px;
      margin-bottom: 8px;

      background: #FCC13F;

      p {
        color: #323232;
        font-size: 40px;
        font-weight: 600;
        line-height: 40px;
        text-align: center;
      }
    }

    .gift_desc {
      margin-top: 12px;
      color: #FFFFFF;
      font-size: 24px;
      font-weight: 500;
      line-height: 28.44px;
      letter-spacing: 1px;
      text-align: left;
    }
  }

  .right_block {
    box-sizing: border-box;
    width: 100%;
    max-width: 520px;

    .gift_invest {
      p {
        color: #FFFFFF;
        font-size: 28px;
        font-weight: 700;
        line-height: 36.4px;
        letter-spacing: 1px;
        text-align: left;

        span {
          color : #FCC13F;
        }
      }

      .button {
        width: 100%;
        max-width: 100%;
        height: 48px;
        display: flex;
        justify-content: center;
        align-items: center;
        padding: 0 18px;
        border-radius: 6px;
        gap: 6px;
        box-sizing: border-box;
        box-shadow: 0 1px 2px 0 #1018280A;
        background: none;
        cursor: pointer;
        transition: 0.3s;
        border: 1px solid #FCC13F;
        background: #FCC13F;
        margin-top: 16px;

        // text
        text-align: center;
        text-transform: uppercase;
        color: #323232;
        font-size: 15px;
        font-weight: 600;
        line-height: 22px;
        letter-spacing: 1.5px;
      }
    }
  }
}

@media (max-width: 1080px) {
  .gift {
    padding: 16px 32px;
    border-radius: 8px;

    .left_block {
      max-width: 330px;

      .gift_sum {
        p {
          font-size: 36px;
          line-height: 36px;
        }
      }

      .gift_desc {
        text-align: center;
        font-size: 20px;
        line-height: 24.44px;
      }
    }

    .right_block {
      max-width: 370px;

      .gift_invest {
        p {
          text-align: center;
          font-size: 20px;
          line-height: 30.4px;
        }
      }
    }
  }
}
@media (max-width: 840px) {
  .gift {
    padding: 24px;

    .left_block {
      max-width: 300px;

      .gift_sum {
        p {
          font-size: 33px;
          line-height: 33px;
        }
      }

      .gift_desc {
        font-size: 18px;
        line-height: 22.44px;
      }
    }

    .right_block {
      max-width: 335px;

      .gift_invest {
        p {
          font-size: 18px;
          line-height: 28.4px;
        }
      }
    }
  }
}

@media (max-width: 767.9px) {
  .gift {
    height: 100%;
    justify-content: center;
    flex-wrap: wrap;

    .left_block {
      .gift_sum {
        width: 100%;
        height: 48px;
        box-sizing: border-box;
        display: flex;
        justify-content: center;
        align-items: center;
        padding: 8px;
        margin-bottom: 8px;

        background: #FCC13F;

        p {
          color: #323232;
          font-size: 40px;
          font-weight: 600;
          line-height: 40px;
          text-align: center;
        }
      }

      .gift_desc {
        color: #FFFFFF;
        font-size: 24px;
        font-weight: 500;
        line-height: 28.44px;
        letter-spacing: 1px;
        text-align: center;
      }
    }

    .right_block {
      .gift_invest {
        margin-bottom: 16px;

        p {
          color: #FFFFFF;
          font-size: 28px;
          font-weight: 700;
          line-height: 36.4px;
          letter-spacing: 1px;
          text-align: center;

          span {
            display: block;
            color : #FCC13F;
          }
        }
      }
    }
  }
}
@media (max-width: 752px) {
  .gift {
    height: 100%;
    justify-content: center;
    flex-wrap: wrap;

    .left_block {
      max-width: 390px;

      .gift_sum {
        width: 100%;
        height: 48px;
        box-sizing: border-box;
        display: flex;
        justify-content: center;
        align-items: center;
        padding: 8px;
        margin-bottom: 8px;

        background: #FCC13F;

        p {
          color: #323232;
          font-size: 40px;
          font-weight: 600;
          line-height: 40px;
          text-align: center;
        }
      }

      .gift_desc {
        color: #FFFFFF;
        font-size: 24px;
        font-weight: 500;
        line-height: 28.44px;
        letter-spacing: 1px;
        text-align: center;
      }
    }

    .right_block {
      max-width: 505px;

      .gift_invest {
        margin-bottom: 16px;

        p {
          color: #FFFFFF;
          font-size: 28px;
          font-weight: 700;
          line-height: 36.4px;
          letter-spacing: 1px;
          text-align: center;

          span {
            display: inline-block;
            color : #FCC13F;
          }
        }
      }
    }
  }
}
@media (max-width: 600px) {
  .gift {
    height: 100%;
    justify-content: center;
    flex-wrap: wrap;
    padding: 15px;

    .left_block {
      .gift_sum {
        width: 100%;
        height: 48px;
        box-sizing: border-box;
        display: flex;
        justify-content: center;
        align-items: center;
        padding: 8px;
        margin-bottom: 8px;

        background: #FCC13F;

        p {
          color: #323232;
          font-size: 36px;
          font-weight: 600;
          line-height: 36px;
          text-align: center;
        }
      }

      .gift_desc {
        color: #FFFFFF;
        font-size: 20px;
        font-weight: 500;
        line-height: 24.44px;
        letter-spacing: 1px;
        text-align: center;
      }
    }

    .right_block {
      .gift_invest {
        margin-bottom: 16px;

        p {
          color: #FFFFFF;
          font-size: 24px;
          font-weight: 700;
          line-height: 32.4px;
          letter-spacing: 1px;
          text-align: center;

          span {
            color : #FCC13F;
          }
        }
      }
    }
  }
}