.form {
  display: flex;
  flex-direction: column;
  gap: 24px;
  padding-bottom: 30px;

  &__title {
    color: #FFFFFF;
    font-size: 24px;
    font-weight: 600;
    line-height: 28.44px;
    letter-spacing: 1px;
    text-align: left;
  }
  &__input_block {
    width: 100%;
    display: flex;
    flex-direction: column;

    input {
      max-height: 48px;
      margin-bottom: 5px;

      border-radius: 6px;
      border: 1px solid #FFFFFF;
    }

    &--tel {
      position: relative;

      .inputPhone {
        padding-left: 40px !important;
      }

      &::after {
        content: '';
        position: absolute;
        top: 11px;
        left: 10px;
        width: 22px;
        height: 23px;
        background-image: url("../../../shared/assets/icons/country/uzbekistan.webp") !important;
        background-size: 22px 22px;
        background-repeat: no-repeat;
      }
    }
  }
  &__input_block_error {
    input {
      border: 1px solid red !important;
    }
  }
  &__title-mess {
    margin: 4px 0 1px 0;
    color: #FFFFFF;
    font-size: 17px;
    font-weight: 500;
    line-height: 25.5px;
    text-align: left;
  }
  &__messengers {
    display: flex;
    gap: 20px;
  }
  &__select_call_hours {
    margin: 0;
  }

  .errors {
    padding: 0;
    margin: 0;
    color: red;

    font-size: 12px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
    letter-spacing: 1px;

    white-space: pre-line;
  }

  input[type="text"],
  input[type="email"],
  input[type="tel"] {
    padding: 12px 16px;
    border: 1px solid #FFFFFF;

    color: #0A0B07;
    font-size: 16px;
    font-weight: 400;
    line-height: 18.96px;
    letter-spacing: 1px;
    text-align: left;
  }

  input[type="text"]::placeholder,
  input[type="email"]::placeholder,
  input[type="tel"]::placeholder {
    color: #0A0B0780;
    font-size: 16px;
    font-weight: 400;
    line-height: 18.96px;
    letter-spacing: 1px;
    text-align: left;
  }

  button {
    width: 100%;
  }
}

.checkbox_container {
  position: relative;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: start;
  cursor: pointer;

  &__text {
    display: inline-block;
    margin-top: 0;
    padding-left: 15px;
  }

  &__text_small {
    margin-top: 4px;
  }

  // default
  [type="checkbox"]:checked,
  [type="checkbox"]:not(:checked) {
    position: absolute;
    left: -9999px;
  }

  // text
  [type="checkbox"]:checked + label,
  [type="checkbox"]:not(:checked) + label
  {
    position: relative;
    padding-left: 18px;
    cursor: pointer;
    display: inline-block;

    color: #D4D4D4;
    font-size: 12px;
    font-weight: 300;
    line-height: 14.22px;
    text-align: left;
  }

  // not-checked box
  [type="checkbox"]:checked + label:before,
  [type="checkbox"]:not(:checked) + label:before {
    content: '';
    position: absolute;
    left: 0;
    top: 10%;

    box-sizing: border-box;
    width: 20px;
    min-width: 20px;
    max-width: 20px;
    height: 20px;
    min-height: 20px;
    max-height: 20px;
    border-radius: 5px;
    border: 1px solid #437EF7;
    background: transparent;
  }

  // checked box
  [type="checkbox"]:checked + label:before {
    background: #437EF7;
    border: 1px solid #437EF7;
  }

  // checked label
  [type="checkbox"]:checked + label:after,
  [type="checkbox"]:not(:checked) + label:after {
    content: '';
    position: absolute;
    width: 20px;
    min-width: 20px;
    max-width: 20px;
    height: 20px;
    min-height: 20px;
    max-height: 20px;
    left: 2px;
    top: calc(10% + 2px);
    border-radius: 5px;
    -webkit-transition: all 0.2s ease;
    transition: all 0.2s ease;

    background-size: 12px 10px;
    background: transparent url("../../../shared/assets/icons/checkbox.svg") no-repeat 2px 3px;
  }
  [type="checkbox"]:not(:checked) + label:after {
    opacity: 0;
    -webkit-transform: scale(0);
    transform: scale(0);
  }
  [type="checkbox"]:checked + label:after {
    opacity: 1;
    -webkit-transform: scale(1);
    transform: scale(1);
  }
}

.checkbox_full_container {
  position: relative;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: start;
  cursor: pointer;

  &__text {
    display: inline-block;
    margin-top: 2px;
    padding-left: 15px;
  }

  // default
  [type="checkbox"]:checked,
  [type="checkbox"]:not(:checked) {
    position: absolute;
    left: -9999px;
  }

  // text
  [type="checkbox"]:checked + label,
  [type="checkbox"]:not(:checked) + label
  {
    position: relative;
    padding-left: 22px;
    padding-top: 4px;
    cursor: pointer;
    display: inline-block;

    color: #FFFFFF;
    font-size: 14px;
    font-weight: 400;
    line-height: 21px;
    text-align: left;
  }

  // not-checked box
  [type="checkbox"]:checked + label:before,
  [type="checkbox"]:not(:checked) + label:before {
    content: '';
    position: absolute;
    left: 0;
    top: 10%;

    box-sizing: border-box;
    width: 24px;
    min-width: 24px;
    max-width: 24px;
    height: 24px;
    min-height: 24px;
    max-height: 24px;
    border-radius: 4px;
    border: 1px solid #437EF7;
    background: transparent;
  }

  // checked box
  [type="checkbox"]:checked + label:before {
    border: 1px solid #437EF7;
  }

  // checked label
  [type="checkbox"]:checked + label:after,
  [type="checkbox"]:not(:checked) + label:after {
    content: '';
    position: absolute;
    width: 16px;
    min-width: 16px;
    max-width: 16px;
    height: 16px;
    min-height: 16px;
    max-height: 16px;
    left: 4px;
    top: calc(10% + 4px);
    border-radius: 4px;
    -webkit-transition: all 0.2s ease;
    transition: all 0.2s ease;

    background: #437EF7;
  }
  [type="checkbox"]:not(:checked) + label:after {
    opacity: 0;
    -webkit-transform: scale(0);
    transform: scale(0);
  }
  [type="checkbox"]:checked + label:after {
    opacity: 1;
    -webkit-transform: scale(1);
    transform: scale(1);
  }
}

.form_modal,
.form_mobile {
  display: flex;
  flex-direction: column;
  gap: 24px;
  padding: 30px 35px;

  .form__title {
    text-align: center;
    color: #323232;
  }
  .form__input_block {
    input {
      border: 1px solid #393939;
    }
  }
  .form__title-mess {
    color: #272D37;
  }

  input[type="text"],
  input[type="email"],
  input[type="tel"] {
    border: 1px solid #393939;
  }

  button {
    width: 100%;
    max-width: 100% !important;
    height: 48px !important;
  }

  .checkbox_container {
    // text
    [type="checkbox"]:checked + label,
    [type="checkbox"]:not(:checked) + label
    {
      color: #000000;
      font-size: 12px;
      font-weight: 300;
      line-height: 14.22px;
      text-align: left;
    }
  }

  .checkbox_full_container {
    // text
    [type="checkbox"]:checked + label,
    [type="checkbox"]:not(:checked) + label
    {
      color: #272D37;
      font-size: 14px;
      font-weight: 400;
      line-height: 21px;
      text-align: left;
    }
  }
}

.form_mobile {
  max-width: 100% !important;
  padding: 0;

  .form__title {
    font-family: var(--font-family-inter);
    font-size: 20px;
    font-weight: 600;
    line-height: 20px;
    letter-spacing: 1px;
    text-align: left;
  }

  .form__title-mess {
    font-size: 14px;
    font-weight: 400;
    line-height: 16.59px;
    letter-spacing: 1px;
    text-align: left;
  }

  .checkbox_container {
    // text
    [type="checkbox"]:checked + label,
    [type="checkbox"]:not(:checked) + label
    {
      font-family: var(--font-family-inter);
      font-size: 12px;
      font-weight: 400;
      line-height: 14.52px;
      text-align: left;
    }
  }

  .checkbox_full_container {
    // text
    [type="checkbox"]:checked + label,
    [type="checkbox"]:not(:checked) + label
    {
      font-family: var(--font-family-inter);
      font-size: 12px;
      font-weight: 400;
      line-height: 18px;
      text-align: left;
    }
  }
}