.page {
  width: 100%;
  height: calc(100dvh - 98px);

  .container {
    height: 100%;

    .content {
      height: calc(100dvh - 98px);
      display: flex;
      flex-direction: column;
      padding: 24px 16px 0;
    }

    .content_container {
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
    }

    .gift_desc {
      max-width: 432px;
      display: flex;
      flex-direction: column;
      gap: 8px;

      &__block1 {
        color: #323232;
        font-family: var(--font-family-inter);
        font-size: 24px;
        font-weight: 500;
        line-height: 29.05px;
        text-align: center;
      }
      &__block2 {
        color: #323232;
        font-family: var(--font-family-inter);
        font-size: 24px;
        font-weight: 700;
        line-height: 29.05px;
        text-align: center;

        p:last-child {
          margin-top: 8px;

          color: #FCC13F;
          font-family: var(--font-family-inter);
          font-size: 32px;
          font-weight: 700;
          line-height: 38.73px;
          text-align: center;
        }
      }
      &__block3 {
        margin-top: 24px;

        color: #323232;
        font-size: 24px;
        font-weight: 500;
        line-height: 28.44px;
        text-align: center;

        p:last-child {
          margin-top: 8px;
          margin-bottom: 25px;

          color: #5F5F5F;
          font-size: 14px;
          font-weight: 400;
          line-height: 16.59px;
          letter-spacing: 1px;
          text-align: center;
        }
      }
    }

    .gift_image {
      max-width: 100%;
      height: auto;

      img {
        width: 100%;
        height: auto;
      }
    }

    a {
      margin: auto 0 0;
      justify-self: flex-end;
      text-decoration: none;

      button {
        margin-bottom: 40px;
      }
    }
  }
}