.main_home {
  background-image: url("../../../shared/assets/images/home/BgDesktop.webp");
  background-position: 100% -25px;
  background-size: contain;
  background-repeat: no-repeat;
}

.main_home_start {
  align-items: start !important;
}

.main_questions {
  background-image: url("../../../shared/assets/images/home/BgDesktop2.webp");
  background-position: 100% 0;
  background-size: cover;
  background-repeat: no-repeat;
}

.main_chat {
  background: #E5E5E5 url("../../../shared/assets/images/chat/BgChat.webp");
  background-size: cover;
  background-repeat: no-repeat;
}

.main_contacts {
  background: #0a0b06 url("../../../shared/assets/images/contacts/BgContacts.webp");
  background-position: 100% 0;
  background-size: contain;
  background-repeat: no-repeat;
}

.main_captcha {
  display: flex;
  justify-content: center;
  align-items: center;
  flex: 1;
  margin-top: 5px;

  background-image: url("../../../shared/assets/images/captcha/BgImg.webp");
  background-size: cover;
  background-repeat: no-repeat;
}

.main_no_bg {
  background: transparent;
}

@media (max-width: 655px) {
  main.main_captcha {
    align-items: start;
    background: none;
  }
}