.logo {
  display: flex;
  align-items: center;

  max-width: 126.05px;
  height: auto;

  img {
    width: 100%;
    height: 100%;
  }
}

.logo_chat {
  max-width: 165px;
}