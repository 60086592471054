.header {
  .layout {
    border-bottom: 1px solid #EAEBF0;
  }

  .left_block {
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 64px;
  }

  .right_block {
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 30px;

    .call_info {
      display: flex;
      justify-content: center;
      align-items: center;
      gap: 5px;

      p {
        max-width: 115px;
        color: #5F6D7E;

        font-family: Inter;
        font-size: 9px;
        font-weight: 400;
        line-height: 12px;
        letter-spacing: -0.02em;
        text-align: left;

      }
    }
  }

  &--fixed {
    z-index: 3;
    width: 100%;
    position: fixed;
  }

  &__left_icon {
    width: 12px;
    height: 12px;
    position: absolute;
    top: 30px;
    right: 30px;
  }
}

.header_chat {
  .layout {
    div {
      justify-content: flex-start !important;
    }
  }
}

.header_contacts {
  .layout {
    min-height: 54px;
    max-height: 54px;
    border-bottom: 1px solid #000000;
    background: #FCC13F;
  }

  .contacts_block {
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;

    p {
      color: #232323;
      font-size: 16px;
      font-weight: 600;
      line-height: 40px;
      text-align: center;
    }
  }
}

@media (max-width: 992px) {
  .header {
    .left_block {
      display: flex;
      justify-content: center;
      align-items: center;
      gap: 32px;
    }
    .right_block {
      display: flex;
      justify-content: center;
      align-items: center;
      gap: 30px;

      button {
        width: 225px;
      }
    }
  }
}

@media (max-width: 767.9px) {
  .header {
    .layout {
      div {
        justify-content: center;
        gap: 20px;
      }
    }
  }
}