.page {
  width: 100%;
  height: calc(100dvh - 78px);
  display: flex;

  .container {
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-items: center;
  }

  .chat {
    width: 100%;
    height: 100%;
    overflow: auto;
  }

  .chat_container {
    display: flex;
    align-items: center;
    justify-content: flex-start;

    padding: 24px 16px;
  }

  .label {
    width: 200px;
    height: 33px;
    box-sizing: border-box;
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 8px 18px;
    margin-top: 25px;
    margin-bottom: 40px;
    border-radius: 3px;
    background: #FFFFFF;
    box-shadow: 0 1px 2px 0 #1018280A;

    p {
      color: #353B48;
      font-family: var(--font-family-inter);
      font-size: 14px;
      font-weight: 600;
      line-height: 16.94px;
      letter-spacing: 2px;
      text-align: left;
    }
  }
}