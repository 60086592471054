.progress_bar {
  width: 100%;

  &__value {
    margin-bottom: 8px;

    font-family: var(--font-family-inter);
    color: #265CFD;
    font-size: 12px;
    font-weight: 500;
    line-height: 14.52px;
    text-align: left;
  }

  &__container {
    width: 100%;
    height: 6px;
    box-sizing: border-box;
    background: #DBDBDB;
    border-radius: 4px;
    position: relative;
  }

  &__percent {
    position: absolute;
    width: 0;
    box-sizing: border-box;
    height: 100%;
    transition: .3s;
    border-radius: 4px;
    background: #FCC13F;

    &:after {
      content: "";
      position: absolute;
      top: 0;
      left: 0;
      bottom: 0;
      right: 0;
      z-index: 1;
      background-size: 20px 20px;
      border-radius: 4px;
      overflow: hidden;
    }
  }
}