.checkboxes {
  display: flex;
  flex-direction: column;
  gap: 20px;
}

.input_container {
  position: relative;
  display: flex;
  flex-direction: column;
  justify-content: center;
  cursor: pointer;
  height: 48px;
  border-radius: 6px;
  text-align: left;
  padding: 0 0 0 25px;
  transition: 0.3s;

  border: 1px solid #9E9E9E;
  box-shadow: 0 1px 2px 0 #1018280A;

  &__label {
    padding: 16px;
    margin-left: 10px;
  }

  &__text {
    display: inline-block;
    padding-top: 1px;
    padding-left: 15px;
  }

  &:hover {
    border: 1px solid #FCC13F;
    transition: 0.3s;
  }

  // default
  [type="radio"]:checked,
  [type="radio"]:not(:checked) {
    position: absolute;
    left: -9999px;
  }

  // text
  [type="radio"]:checked + label,
  [type="radio"]:not(:checked) + label
  {
    position: relative;
    padding-left: 16px;
    cursor: pointer;
    display: inline-block;

    color: #141414;
    font-size: 16px;
    font-weight: 400;
    line-height: 18.96px;
    letter-spacing: 2px;
    text-align: left;
  }

  // not-checked box
  [type="radio"]:checked + label:before,
  [type="radio"]:not(:checked) + label:before {
    content: '';
    position: absolute;
    left: 0;
    top: 30%;
    box-sizing: border-box;

    width: 20px;
    min-width: 20px;
    max-width: 20px;
    height: 20px;
    min-height: 20px;
    max-height: 20px;
    background: #FFFFFF;

    background: url("../../../../shared/assets/icons/checkboxes/CheckIcon1.webp") no-repeat 0 0;
    background-size: 20px;
    background-position: center;
  }

  // checked box
  [type="radio"]:checked + label:before {
    border: 1px solid transparent;
  }

  // checked label
  [type="radio"]:checked + label:after,
  [type="radio"]:not(:checked) + label:after {
    content: '';
    position: absolute;
    width: 16px;
    min-width: 16px;
    max-width: 16px;
    height: 16px;
    min-height: 16px;
    max-height: 16px;
    left: 2px;
    top: calc(30% + 2px);
    -webkit-transition: all 0.2s ease;
    transition: all 0.2s ease;

    background: url("../../../../shared/assets/icons/checkboxes/CheckIcon2.webp") no-repeat 0 0;
    background-size: 20px;
    background-position: center;
  }
  [type="radio"]:not(:checked) + label:after {
    opacity: 0;
    -webkit-transform: scale(0);
    transform: scale(0);
  }
  [type="radio"]:checked + label:after {
    opacity: 1;
    -webkit-transform: scale(1);
    transform: scale(1);
  }
}