.header {
  .layout {
    height: 100%;
    min-height: 72.44px;
    max-height: 72.44px;
    display: flex;
    justify-content: center;
    padding: 16px 30px;
    gap: 10px;
    box-sizing: border-box;
    border-bottom: 1px solid #EAEBF0;
    background-color: #393939;
  }

  .container {
    justify-content: center;
  }

  &--fixed {
    z-index: 3;
    width: 100%;
    position: fixed;
  }
}