.button {
  width: 100%;
  height: 100%;
  padding: 0 18px;
  border-radius: 6px;
  gap: 6px;
  box-sizing: border-box;
  box-shadow: 0 1px 2px 0 #1018280A;
  background: none;
  cursor: pointer;
  transition: 0.3s;

  // text
  color: #FFFFFF;
  text-align: center;
  text-transform: uppercase;
  font-size: 16px;
  font-weight: 700;
  line-height: 18.96px;
  letter-spacing: 2px;

  &.default {
    max-width: 470px;
    height: 51px;
    background: #FCC13F;
    border: 1px solid #FCC13F;
    color: #FEFCF8;

    &:hover {
      background: #E7A410;
      border-color: #E7A410;
    }
  }

  &.variant2 {
    max-width: 470px;
    height: 48px;
    border: 1px solid #FCC13F;
    background: #FCC13F;

    // text
    color: #323232;
    font-size: 15px;
    font-weight: 600;
    line-height: 22px;
    letter-spacing: 1.5px;

    &:hover {
      background: #E7A410;
      border-color: #E7A410;
    }
  }

  &.variant3 {
    max-width: 235px;
    height: 46px;
    border: 3px solid #FCC13F;
    background: transparent;

    // text
    color: #FCC13F;
    font-family: var(--font-family-ibm);
    font-size: 15px;
    font-weight: 600;
    line-height: 22px;
    letter-spacing: 1.5px;

    &:hover {
      color: #323232;
      background: #FCC13F;
    }
  }

  &.variant4 {
    max-width: 470px;
    height: 48px;
    border: 1px solid #FCC13F;
    background: #FCC13F;

    // text
    color: #000000;
    font-size: 15px;
    font-weight: 600;
    line-height: 22px;
    letter-spacing: 1.5px;

    &:hover {
      background: #E7A410;
      border-color: #E7A410;
    }
  }

  &.wrapper {
    width: 100%;
    max-width: 100%;
    height: 100%;
    padding: 0;
    margin: 0;
    border-radius: 0;
    border: none;
  }

  &.captcha {
    width: 100%;
    height: 48px;
    box-sizing: border-box;
    border-radius: 6px;
    background: #FCC13F;
    border: 1px solid #FCC13F;
    box-shadow: 0 1px 2px 0 #1018280A;

    cursor: pointer;
    transition: 0.3s;

    // text
    color: #393939;
    text-align: center;
    text-transform: uppercase;
    font-size: 16px;
    font-weight: 600;
    line-height: 18.96px;
    letter-spacing: 2px;

    &:hover {
      background: #E7A410;
      border-color: #E7A410;
    }
  }
}

.button_icon {
  display: flex;
  justify-content: center;
  align-items: center;
}

.icon {
  display: inline-block;
  box-sizing: border-box;

  img {
    width: 20px;
    height: auto;
  }

  &__left {
    margin-bottom: -3px;
  }
  &__right {
    margin-bottom: -3px;
  }
}