.page {
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 0;

  .page_container {
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    padding: 8px 32px;
  }

  .breadcrumb {
    color: #4285F4;
    font-family: var(--font-family-rubic);
    font-size: 12px;
    font-weight: 500;
    line-height: 14.52px;
    text-align: left;
  }

  .container {
    width: 100%;
    display: flex;
    flex-direction: column;
    gap: 19px;
    padding: 24px;
    border: 1px solid #EDEDED;
    border-radius: 8px;
    background: #FFFFFF;
    box-shadow: 3px 4px 13px 1px #00000040;
  }

  .content {
    display: flex;
    gap: 24px;

    .left_block {
      width: 100%;
      max-width: 564px;
      height: 100%;
      max-height: 576px;
      box-sizing: border-box;

      img {
        width: 100%;
        height: 100%;
      }
    }

    .right_block {
      width: 100%;
      max-width: 564px;
      min-height: 100%;
      max-height: 576px;
      display: flex;
      justify-content: center;
      align-items: center;
      gap: 24px;
      box-sizing: border-box;
      padding: 32px 16px;
      border-radius: 8px;
      background: #393939;
    }
  }
}

@media (max-width: 655px) {
  .page {

    .page_container {
      padding: 0 16px;
    }

    .breadcrumb {
      color: #4285F4;
      font-size: 12px;
      font-weight: 600;
      line-height: 14.52px;
      text-align: left;
    }

    .container {
      gap: 16px;
      padding: 16px 0;
      border: none;
      box-shadow: none;
    }

    .content {
      width: 100%;
      justify-content: center;
      flex-wrap: wrap;
      gap: 16px;
      padding: 0;

      .left_block {
        max-width: 100%;
        max-height: 100%;
        padding: 0
      }


      .right_block {
        max-width: 100%;
        max-height: 100%;
        gap: 16px;
      }
    }
  }
}
@media (max-width: 500px) {
  .page {
    .content {
      .right_block {
        padding: 0;
        background: none;

        form {
          width: 100%;
        }
      }
    }
  }
}